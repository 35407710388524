<template>
  <div>
    <b-dropdown
      v-if="showDropdown"
      class="context-menu"
      toggle-class="dot-button"
      no-caret
      dropup
      no-flip
      right
    >
      <template #button-content>
        <b-icon icon="three-dots-vertical" aria-hidden="true" scale=".8"></b-icon>
      </template>
      <b-dropdown-item
        v-if="showInviteUserItem"
        @click="sendInvitationReminder(data.item.email, data.item.name)">
        {{ $t('dropdowns.invitation') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="showEditUserItem"
        @click="showEditUserRoleModal = true"
      >
        {{ $t('dropdowns.editUser') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="showRemoveUserItem"
        @click="showDeleteUserRoleModal = true">
        {{ $t('dropdowns.remove') }}
      </b-dropdown-item>
    </b-dropdown>
    <modal-edit-user-role
      v-if="showEditUserRoleModal"
      class="d-inline-block"
      :user-object="userObject"
      :group-id="group.group_id"
      :is-sys-admin="isSysAdmin"
      :current-user-role="group.user_role"
      @editUser="editUser"
      @closeModal="showEditUserRoleModal = false"
    ></modal-edit-user-role>
    <modal-delete-user
      v-if="showDeleteUserRoleModal"
      class="d-inline-block"
      :userUserId="userObject.userId"
      :group-id="group.group_id"
      :current-user-id="currentUserId"
      @deleteUser="deleteUser"
      @closeModal="showDeleteUserRoleModal = false"
    ></modal-delete-user>
  </div>
</template>

<script>
export default {
  name: 'GroupUserManagementDropdown',
  components: {
    ModalEditUserRole: () => import('@/components/modals/ModalEditUserRole.vue'),
    ModalDeleteUser: () => import('@/components/modals/ModalDeleteUser.vue'),
  },
  props: [
    'data',
    'tableIndex',
    'group',
    'isSysAdmin',
    'userPrivileges',
    'currentUserId',
    'teamIndex',
    'users',
    'userObject',
    'inTeam',
  ],
  data: () => ({
    showEditUserRoleModal: false,
    showDeleteUserRoleModal: false,
  }),
  computed: {
    showDropdown() {
      return this.showRemoveUserItem
        || this.showEditUserItem
        || this.showInviteUserItem;
    },
    showEditUserItem() {
      return (this.group.user_role !== 'manager' && (this.isSysAdmin
          || this.$hasPrivileges(this.$privileges('CAN_EDIT_ADMINS_OF_GROUP'), this.isSysAdmin, this.userPrivileges))
        && ((this.data.item.role.name === 'Administrator' ? !this.isLastAdmin : true)));
    },
    showInviteUserItem() {
      return (this.data.item.status === this.$t('groupManagement.invited'));
    },
    showRemoveUserItem() {
      if (this.isLastAdmin && this.data.item.role.handle === 'administrator') {
        return false;
      }
      return !(this.group.user_role === 'manager' && this.data.item.role.handle !== 'manager');
    },
    isLastAdmin() {
      return this.users.filter((user) => user.role.handle === 'administrator').length <= 1;
    },
  },
  methods: {
    editUser() {
      this.showEditUserRoleModal = false;
      this.$emit('editUser');
    },
    deleteUser(userId) {
      this.showDeleteUserRoleModal = false;
      this.$emit('deleteUser', userId);
    },
    sendInvitationReminder(email, name) {
      this.$emit('sendInvitationReminder', email, name);
    },
  },
};
</script>

<style scoped>

</style>
